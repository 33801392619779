import { useContainer } from '@/plugins/inversify';
import { ISearchService, ISearchServiceId } from '@/services';
import { type PlaceAutocompleteModelDto, type TripDto, type TripPatternDto, GetTripModeDto, PlaceType } from '@/types/webapi';
import { defineStore, storeToRefs } from 'pinia';
import { isArray, isEmpty, isEqual, merge, mergeWith, omit } from 'lodash-es';
import { type TripSearchRequest, type TripSearchFilters, WalkSpeedType } from '@/features/trips/models';
import { computed, reactive, toRefs, toValue, watch } from 'vue';
import { useMainStore } from '@/features/common/stores';
import { type SearchHistoryItem } from '@/features/places/models';
import { usePlaceAutocompleteHistory } from '@/features/places/composables';
import { isDefined } from '@vueuse/core';
import { DateType } from '@/features/common/models';
import { type AxiosError } from 'axios';
import { useGtm } from 'vue-gtm';
import { updateLegsTimeStatusFlags } from '@/helpers';
import { updateTripPatternBookingNotifications } from '@/features/bookings/composables';
import { useBookingStore } from '@/features/bookings/stores';

const defaultSearchFilters: TripSearchFilters = {
    modes: [],
    walkSpeed: WalkSpeedType.Normal,
    transferSlack: 120,
    showTripsWithTransfers: true,
    lines: []
};

const defaultSearchRequest: TripSearchRequest = {
    from: null,
    to: null,
    dateTime: undefined,
    dateTimeType: DateType.Now,
    filters: { ...defaultSearchFilters },
    nextPageCursors: {},
    previousPageCursors: {}
};

const defaultSearchResult = {
    errors: [] as string[],
    trip: null as TripDto | null
};

const useTripSearchStore = defineStore('tripSearchStore', () => {
    const container = useContainer();
    const tripSearchService = container.get<ISearchService>(ISearchServiceId);
    const { addItem } = usePlaceAutocompleteHistory<SearchHistoryItem>('historyItems');
    const { addItem: addPastPlace } = usePlaceAutocompleteHistory<SearchHistoryItem>('pastPlaces');
    const mainStore = useMainStore();
    const { devModeConfiguration } = storeToRefs(mainStore);
    const gtm = useGtm();
    const bookingStore = useBookingStore();

    const state = reactive({
        isDirty: false,
        searchRequest: { ...defaultSearchRequest },
        selectedTripPatternId: null as string | null,
        selectedTripPatternIdIsExpanded: false,
        selectedMode: GetTripModeDto.Bus
    });
    const searchResult = reactive({ ...defaultSearchResult });
    const _footSearchResult = reactive({ ...defaultSearchResult });
    const footSearchResult = computed(() =>
        _footSearchResult.trip ? Object.values(_footSearchResult.trip.tripPatternGroups)[0]?.[0] : undefined
    );
    const _bikeSearchResult = reactive({ ...defaultSearchResult });
    const bikeSearchResult = computed(() =>
        _bikeSearchResult.trip ? Object.values(_bikeSearchResult.trip.tripPatternGroups)[0]?.[0] : undefined
    );

    const searchingState = reactive({
        transitIsSearching: false,
        footIsSearching: false,
        bikeIsSearching: false
    });
    const loadingStates = reactive({
        transitIsLoading: false,
        footIsLoading: false,
        bikeIsLoading: false
    });
    const isSearching = computed(
        () => searchingState.transitIsSearching || searchingState.footIsSearching || searchingState.bikeIsSearching
    );
    const isLoading = computed(() => loadingStates.transitIsLoading || loadingStates.footIsLoading || loadingStates.bikeIsLoading);
    const canSearch = computed(() => isValidSearchRequest(state.searchRequest));
    const filterIsDirty = computed(() => !isEqual(toValue(state.searchRequest.filters), defaultSearchFilters));
    const hasResult = computed(() => Boolean(searchResult.trip));
    const tripPatternGroups = computed(
        (): Record<string, TripPatternDto[]> =>
            removeDuplicateTripPatterns(
                updateTripPatternBookingNotifications(
                    updateLegsTimeStatusFlags(
                        setIsMyLocation(removeHiddenLegs(searchResult.trip?.tripPatternGroups || {}), state.searchRequest),
                        mainStore.currentTime
                    ),
                    bookingStore.bookings,
                    bookingStore.stopSignalOrders
                )
            )
    );
    const tripPatterns = computed(() => Object.values(tripPatternGroups.value).flatMap(x => x));
    const selectedTripPattern = computed((): TripPatternDto | undefined => {
        if (!state.selectedTripPatternId) return undefined;

        return (
            tripPatterns.value.find(x => x.id === state.selectedTripPatternId) ||
            (state.selectedTripPatternId === footSearchResult.value?.id && footSearchResult.value) ||
            (state.selectedTripPatternId === bikeSearchResult.value?.id && bikeSearchResult.value) ||
            undefined
        );
    });

    const isInNonTransitMode = computed(() =>
        [GetTripModeDto.Foot, GetTripModeDto.Bicycle].some(m => state.searchRequest.filters.modes.includes(m))
    );

    function $reset() {
        resetResult();

        state.isDirty = false;
        state.searchRequest = { ...defaultSearchRequest };
        state.selectedTripPatternId = null;
        state.selectedTripPatternIdIsExpanded = false;
        state.selectedMode = GetTripModeDto.Bus;
    }

    function resetResult() {
        Object.assign(searchResult, { ...defaultSearchResult });
        Object.assign(_bikeSearchResult, { ...defaultSearchResult });
        Object.assign(_footSearchResult, { ...defaultSearchResult });
    }

    function resetFilters() {
        state.searchRequest.filters = { ...defaultSearchFilters };
    }

    function swapFromTo() {
        state.searchRequest = {
            ...state.searchRequest,
            from: state.searchRequest.to,
            to: state.searchRequest.from
        };
    }

    function isValidSearchRequest(
        request: TripSearchRequest
    ): request is { from: PlaceAutocompleteModelDto; to: PlaceAutocompleteModelDto } & TripSearchRequest {
        return (
            isDefined(request.from?.id) &&
            request.from.type !== PlaceType.Unknown &&
            isDefined(request.to?.id) &&
            request.to.type !== PlaceType.Unknown
        );
    }

    function selectFirstTripPattern() {
        if (!tripPatterns.value.length) return;

        state.selectedTripPatternId = tripPatterns.value[0].id;
    }

    let searchAbortController: AbortController | undefined;

    async function _executeSearch(searchFunc: Function, onError?: Function) {
        try {
            await searchFunc();
        } catch (e) {
            const { name } = e as AxiosError;
            // in case of AbortController being triggered, do nothing
            if (name === 'CanceledError') return;

            mainStore.registerError(e);
            onError?.();
        }
    }

    // foot mode search
    async function searchFoot({ signal }: { signal: AbortSignal }) {
        if (!isValidSearchRequest(state.searchRequest)) return;

        await _executeSearch(
            async () => {
                loadingStates.footIsLoading = true;
                searchingState.footIsSearching = true;
                // force mode
                const searchRequest = merge({}, state.searchRequest, { filters: { modes: [GetTripModeDto.Foot] } });
                _footSearchResult.trip = await tripSearchService.searchForTrips(searchRequest, { signal });

                searchingState.footIsSearching = false;
                loadingStates.footIsLoading = false;
            },
            () => {
                searchingState.footIsSearching = false;
                loadingStates.footIsLoading = false;
            }
        );
    }

    // bike mode search
    async function searchBike({ signal }: { signal: AbortSignal }) {
        if (!isValidSearchRequest(state.searchRequest)) return;

        loadingStates.bikeIsLoading = true;

        await _executeSearch(
            async () => {
                searchingState.bikeIsSearching = true;
                // force mode
                const searchRequest = merge({}, state.searchRequest, { filters: { modes: [GetTripModeDto.Bicycle] } });
                _bikeSearchResult.trip = await tripSearchService.searchForTrips(searchRequest, { signal });

                searchingState.bikeIsSearching = false;
                loadingStates.bikeIsLoading = false;
            },
            () => {
                searchingState.bikeIsSearching = false;
                loadingStates.bikeIsLoading = false;
            }
        );
    }

    async function searchTransit({ signal }: { signal: AbortSignal }) {
        if (!isValidSearchRequest(state.searchRequest)) return;
        loadingStates.transitIsLoading = true;

        await _executeSearch(
            async () => {
                searchingState.transitIsSearching = true;

                // TODO: check for bookings against booking store
                searchResult.trip = await tripSearchService.searchForTrips(state.searchRequest, { signal });

                searchingState.transitIsSearching = false;
                loadingStates.transitIsLoading = false;
            },
            () => {
                searchingState.transitIsSearching = false;
                loadingStates.transitIsLoading = false;
            }
        );
    }

    function _search({ signal }: { signal: AbortSignal }) {
        if (signal.aborted) {
            return Promise.reject(new DOMException('Cancelled', 'CanceledError'));
        }

        return new Promise<void>((resolve, reject) => {
            // if searchRequest is not valid, do nothing
            if (!isValidSearchRequest(state.searchRequest)) return reject();

            signal.addEventListener('abort', () => reject(new DOMException('Cancelled', 'CanceledError')));

            // remove distance and isMyLocation
            const from = omit(state.searchRequest.from, ['distance', 'isMyLocation']);
            const to = omit(state.searchRequest.to, ['distance', 'isMyLocation']);

            // save individual places
            addPastPlace({ id: from.id, from });
            addPastPlace({ id: to.id, from: to });

            // register history
            addItem({ id: `${from.id}__${to.id}`, from, to });

            Promise.all([searchTransit({ signal }), searchFoot({ signal }), searchBike({ signal })])
                .then(() => {
                    // track event
                    gtm?.trackEvent({
                        event: 'app_event',
                        category: 'trip_search',
                        action: 'search',
                        hasTransitStation: from.type === PlaceType.TransitStation || to.type === PlaceType.TransitStation,
                        hasDateTime: !!state.searchRequest.dateTime,
                        hasFilters: !isEqual(defaultSearchFilters, state.searchRequest.filters)
                    });

                    resolve();
                })
                .catch(reject);
        });
    }

    // search all modes
    async function search() {
        await _executeSearch(async () => {
            // if searchRequest is not valid, do nothing
            if (!isValidSearchRequest(state.searchRequest)) return;

            // abort previous request
            searchAbortController?.abort();
            searchAbortController = new AbortController();

            resetResult();
            state.isDirty = true;

            await _search({ signal: searchAbortController!.signal });
        });
    }

    function cancelSearch() {
        searchAbortController?.abort();
    }

    async function searchMore() {
        if (searchingState.transitIsSearching || !isValidSearchRequest(state.searchRequest)) return;

        loadingStates.transitIsLoading = true;

        try {
            if (!isEmpty(searchResult.trip?.nextPageCursors) || !isEmpty(searchResult.trip?.previousPageCursors)) {
                searchingState.transitIsSearching = true;
                // we need to append trip patterns to groups
                // and save next page cursors
                const { tripPatternGroups, nextPageCursors, previousPageCursors } =
                    (await tripSearchService.searchForMoreTrips(
                        merge({}, state.searchRequest, {
                            nextPageCursors: searchResult.trip?.nextPageCursors,
                            previousPageCursors: searchResult.trip?.previousPageCursors
                        })
                    )) || {};

                mergeWith(searchResult.trip?.tripPatternGroups || {}, tripPatternGroups, mergeArray);

                if (searchResult.trip) {
                    searchResult.trip.nextPageCursors = nextPageCursors || {};
                    searchResult.trip.previousPageCursors = previousPageCursors || {};
                }
            }
        } catch (e) {
            mainStore.registerError(e);
        } finally {
            searchingState.transitIsSearching = false;
            loadingStates.transitIsLoading = false;
        }
    }

    watch(
        () => state.selectedTripPatternId,
        (value, oldValue) => {
            if (value === oldValue) return;

            // reset expanded item
            state.selectedTripPatternIdIsExpanded = false;
        }
    );

    watch(hasResult, value => {
        if (!value || !tripPatterns.value.length) {
            state.selectedTripPatternId = null;
            state.selectedTripPatternIdIsExpanded = false;
            return;
        }

        // check if selectedTripPatternId exists in list
        // if it doesn't, reset selected trip pattern stuff
        if (!tripPatterns.value.some(x => x.id === state.selectedTripPatternId)) {
            // TODO: show error for invalid trip pattern ID?
            state.selectedTripPatternId = null;
            state.selectedTripPatternIdIsExpanded = false;
        }
    });

    // trigger search when enturEndpointType changes
    watch(devModeConfiguration, async (value, oldValue) => {
        if (isEqual(value, oldValue)) return;

        await search();
    });

    return {
        ...toRefs(state),
        ...toRefs(searchingState),
        ...toRefs(loadingStates),
        isSearching,
        isLoading,
        canSearch,
        filterIsDirty,
        hasResult,
        tripPatternGroups,
        selectedTripPattern,
        searchResult,
        footSearchResult,
        bikeSearchResult,
        $reset,
        resetResult,
        swapFromTo,
        search,
        searchMore,
        cancelSearch,
        resetFilters,
        selectFirstTripPattern,
        isInNonTransitMode
    };
});

function mergeArray(obj: any, src: any) {
    if (isArray(obj)) {
        return obj.concat(src);
    }
}

/**
 * Remove hidden legs from trip patterns
 * @param tripPatternGroups - The trip pattern groups to remove hidden legs from
 * @returns The trip pattern groups with hidden legs removed
 */
function removeHiddenLegs(tripPatternGroups: Record<string, TripPatternDto[]>): Record<string, TripPatternDto[]> {
    return Object.fromEntries(
        Object.entries(tripPatternGroups).map(([key, tripPatterns]) => [
            key,
            tripPatterns.map(x => ({
                ...x,
                legs: x.legs?.filter(y => !y.isHidden)
            }))
        ])
    );
}

/**
 * Set isMyLocation for origin/destination
 * @param tripPatternGroups - The trip pattern groups to set isMyLocation for
 * @param searchRequest - The search request
 * @returns The trip pattern groups with isMyLocation set for origin/destination
 */
function setIsMyLocation(
    tripPatternGroups: Record<string, TripPatternDto[]>,
    searchRequest: TripSearchRequest
): Record<string, TripPatternDto[]> {
    return Object.fromEntries(
        Object.entries(tripPatternGroups).map(([key, tripPatterns]) => [
            key,
            tripPatterns.map(tripPattern => {
                // check if isMyLocation is set for origin/destination
                const myLocationPlaceId = searchRequest.from?.isMyLocation
                    ? searchRequest.from.id
                    : searchRequest.to?.isMyLocation
                      ? searchRequest.to.id
                      : undefined;

                return {
                    ...tripPattern,
                    legs: tripPattern.legs?.map(leg => ({
                        ...leg,
                        from: { ...leg.from, isMyLocation: leg.from.id === myLocationPlaceId },
                        to: { ...leg.to, isMyLocation: leg.to.id === myLocationPlaceId }
                    }))
                };
            })
        ])
    );
}

/**
 * Remove duplicate trip patterns
 * @param tripPatternGroups - The trip pattern groups to remove duplicate trip patterns from
 * @returns The trip pattern groups with duplicate trip patterns removed
 */
function removeDuplicateTripPatterns(tripPatternGroups: Record<string, TripPatternDto[]>): Record<string, TripPatternDto[]> {
    return Object.fromEntries(
        Object.entries(tripPatternGroups).map(([key, tripPatterns]) => [
            key,
            tripPatterns.filter((tripPattern, index, self) => index === self.findIndex(t => t.id === tripPattern.id))
        ])
    );
}

export default useTripSearchStore;
