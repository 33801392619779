import { DepartureDto, TripPatternDto } from '@/types/webapi';

const REALTIME_THRESHOLD_MINUTES = 20 * 60 * 1000; // 20 minutes in milliseconds

/**
 * Update the legs of the trip patterns with time status flags
 * @param tripPatternGroups - The trip pattern groups to update
 * @param currentTime - The current time
 * @returns The updated trip pattern groups
 */
export function updateLegsTimeStatusFlags(
    tripPatternGroups: Record<string, TripPatternDto[]>,
    currentTime: Date
): Record<string, TripPatternDto[]> {
    return Object.keys(tripPatternGroups).reduce(
        (updatedGroups, groupKey) => {
            updatedGroups[groupKey] = tripPatternGroups[groupKey].map(tripPattern => ({
                ...tripPattern,
                legs:
                    tripPattern.legs?.map(leg => {
                        if (!leg.aimedStartTime || !leg.aimedEndTime) return leg;

                        const startTime = new Date(leg.aimedStartTime).getTime();
                        const endTime = new Date(leg.aimedEndTime).getTime();
                        const currentTimeMs = currentTime.getTime();

                        return {
                            ...leg,
                            isStartingSoon: startTime - currentTimeMs <= REALTIME_THRESHOLD_MINUTES,
                            isEndingSoon: endTime - currentTimeMs <= REALTIME_THRESHOLD_MINUTES
                        };
                    }) || []
            }));

            return updatedGroups;
        },
        {} as Record<string, TripPatternDto[]>
    );
}

/**
 * Update the legs of the departures with time status flags
 * @param departureGroups - The departure groups to update
 * @param currentTime - The current time
 * @returns The updated departure groups
 */
export function updateDeparturesTimeStatusFlags(
    departureGroups: Record<string, DepartureDto[]>,
    currentTime: Date
): Record<string, DepartureDto[]> {
    return Object.keys(departureGroups).reduce(
        (updatedGroups, groupKey) => {
            updatedGroups[groupKey] = departureGroups[groupKey].map(departure => ({
                ...departure,
                estimatedCalls:
                    departure.estimatedCalls?.map(estimatedCall => {
                        if (!estimatedCall.aimedStopTime) return estimatedCall;

                        const stopTime = estimatedCall.aimedStopTime.getTime();
                        const currentTimeMs = currentTime.getTime();

                        return {
                            ...estimatedCall,
                            isCallingSoon: stopTime - currentTimeMs <= REALTIME_THRESHOLD_MINUTES
                        };
                    }) || []
            }));

            return updatedGroups;
        },
        {} as Record<string, DepartureDto[]>
    );
}
