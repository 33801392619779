import { BookingDto, StopSignalOrderDto } from '@/types/webapi';
import { isSameDay } from 'date-fns';

/**
 * Check if the stop signal order is valid
 * @param stopSignalOrder - The stop signal order to check
 * @param params - The params to check against
 * @returns True if the stop signal order is valid, false otherwise
 */
export function hasValidStopSignalOrder(
    stopSignalOrder: StopSignalOrderDto,
    params?: { serviceJourneyRef?: string; activeDate?: Date; stopSequence?: number }
) {
    if (!params?.serviceJourneyRef || !params.activeDate || !params.stopSequence) return false;

    return (
        stopSignalOrder.serviceJourneyRef === params.serviceJourneyRef &&
        isSameDay(stopSignalOrder.activeDate, params.activeDate) &&
        stopSignalOrder.stopSequenceNumber === params.stopSequence &&
        !stopSignalOrder.cancelledTimeUtc
    );
}

/**
 * Check if the booking is valid
 * @param booking - The booking to check
 * @param params - The params to check against
 * @returns True if the booking is valid, false otherwise
 */
export function hasValidBooking(booking: BookingDto, params?: { serviceJourneyRef?: string; activeDate?: Date; stopSequence?: number }) {
    if (!params?.serviceJourneyRef || !params.activeDate || !params.stopSequence) return false;

    return (
        booking.serviceJourneyRef === params.serviceJourneyRef &&
        isSameDay(booking.activeDate, params.activeDate) &&
        booking.stopSequence === params.stopSequence
    );
}
