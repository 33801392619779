<template>
    <v-sheet color="transparent" class="search-mode-toggle" :class="cssClasses">
        <div class="search-mode-toggle__content rounded-t-lg">
            <search-mode-toggle-button
                data-id="tripSearchModeToggleBtn"
                :model-value="model === SearchMode.Trip"
                :label="t('trips.resources.title', 'Travel planner')"
                rounded="ts-lg"
                @click="model = SearchMode.Trip"
            />
            <search-mode-toggle-button
                data-id="departuresSearchModeToggleBtn"
                :model-value="model === SearchMode.Departures"
                :label="t('departures.resources.title', 'Departures')"
                rounded="te-lg"
                @click="model = SearchMode.Departures"
            />
        </div>
    </v-sheet>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { SearchMode } from '@/features/common/stores/mainStore';
import { SearchModeToggleButton } from '.';
import { computed } from 'vue';

interface Props {
    /**
     * The currently selected search mode (Trip or Departures)
     */
    modelValue: SearchMode;

    /**
     * Whether the toggle buttons are disabled
     * @default false
     */
    disabled?: boolean;
}

const props = defineProps<Props>();
const model = defineModel<SearchMode>('modelValue', { required: true });
const { t } = useI18n();
const cssClasses = computed(() => ({ 'search-mode-toggle--disabled': props.disabled }));
</script>

<style lang="scss">
@use '@geta/kolumbus-frontend/styles/vuetify' as *;
@use 'vuetify/lib/styles/tools' as *;

.search-mode-toggle {
    --search-mode-toggle__btn-bg-color: var(--v-color-grey-lighten-3);
    --search-mode-toggle__btn-border-color: var(--v-color-grey-lighten-2);
    --search-mode-toggle__btn-width: 50%;

    position: relative;
    margin-bottom: -1px;
    z-index: 1;
    transition-property: opacity;
    transition-duration: 0.2;
    transition-timing-function: ease-in-out;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
        --search-mode-toggle__btn-width: 198px;
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        background-color: var(--search-mode-toggle__btn-bg-color, #f8f8f8);
        border-color: var(--search-mode-toggle__btn-border-color, #e6e6e6);
        border-style: solid;
        border-width: 0;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            width: calc(var(--search-mode-toggle__btn-width) * 2 + 2px);
            border-width: 1px 1px 0 1px;
        }
    }

    &--disabled {
        opacity: var(--v-disabled-opacity, 0.38);
        pointer-events: none;
    }

    .search-mode-toggle__btn {
        width: var(--search-mode-toggle__btn-width);
    }
}
</style>
