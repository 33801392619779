import { nextTick, watch, WatchHandle } from 'vue';
import { useBookingStore } from '../stores';
import { useMainStore } from '@/features/common/stores';
import { useTripSearchStore } from '@/features/trips/stores';
import { useDeparturesSearchStore } from '@/features/departures/stores';
import { isAfter, isDate, isSameDay } from 'date-fns';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@geta/kolumbus-frontend/features/authentication';
import { isEmpty } from 'lodash-es';
import { DepartureDto, LegDto } from '@/types/webapi';

export function useBookingFeature() {
    const mainStore = useMainStore();
    const userStore = useUserStore();
    const bookingStore = useBookingStore();
    const tripSearchStore = useTripSearchStore();
    const departureSearchStore = useDeparturesSearchStore();
    const { bookings, maxBookingExceeded, selectedBooking } = storeToRefs(bookingStore);
    const { selectedDeparture, hasResult: hasDepartureResult } = storeToRefs(departureSearchStore);
    const { selectedTripPattern, hasResult: hasTripPatternResult } = storeToRefs(tripSearchStore);

    async function loadBookingData(legOrDeparture: LegDto | DepartureDto) {
        if (!legOrDeparture.bookingMetadata) return;

        // if booking limit date time has been exceeded, load stop signal order
        const { bookingLimitDateTime } = legOrDeparture.bookingMetadata;
        if (isDate(bookingLimitDateTime) && isAfter(mainStore.currentTime, bookingLimitDateTime)) {
            await bookingStore.loadStopSignalOrder();
        }

        await nextTick();

        if (!legOrDeparture.bookingMetadata) return;

        // set selected booking based on selected legOrDeparture, if it exists
        selectedBooking.value = bookings.value.find(
            x =>
                x.serviceJourneyRef === legOrDeparture.bookingMetadata!.serviceJourneyRef &&
                isDate(legOrDeparture.bookingMetadata!.activeDate) &&
                isSameDay(x.activeDate, legOrDeparture.bookingMetadata!.activeDate) &&
                x.stopSequence === legOrDeparture.bookingMetadata!.stopSequence
        );
    }

    function reset() {
        selectedBooking.value = undefined;
        maxBookingExceeded.value = false;
    }

    let resultWatcher: WatchHandle | undefined;
    let tripPatternWatcher: WatchHandle | undefined;
    let departureWatcher: WatchHandle | undefined;

    function startWatchers() {
        // load user bookings when hasResult changes
        resultWatcher = watch([hasDepartureResult, hasTripPatternResult], async ([hasDepartureResult, hasTripPatternResult]) => {
            reset();

            if (!userStore.isLoggedIn || (!hasDepartureResult && !hasTripPatternResult)) return;

            // load user bookings
            await bookingStore.loadBookings();
        });

        tripPatternWatcher = watch(
            selectedTripPattern,
            async (value, oldValue) => {
                if (isEmpty(value) || value.id === oldValue?.id) return;

                reset();

                const bookingLeg = value.legs?.find(x => x.bookingMetadata?.onlineBookingIsAvailable);
                if (!bookingLeg) return;

                await loadBookingData(bookingLeg);
            },
            { immediate: true }
        );

        departureWatcher = watch(
            selectedDeparture,
            async (value, oldValue) => {
                if (isEmpty(value?.bookingMetadata) || value.isCancelled || value.id === oldValue?.id) return;

                reset();

                await loadBookingData(value);
            },
            { immediate: true }
        );
    }

    function stopWatchers() {
        resultWatcher?.();
        tripPatternWatcher?.();
        departureWatcher?.();
    }

    watch(
        () => mainStore.config.bookingConfig?.enabled,
        value => {
            if (value) {
                startWatchers();
            } else {
                stopWatchers();
            }
        },
        {
            immediate: true
        }
    );
}
