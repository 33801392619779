import {
    type DepartureDto,
    type LegDto,
    NotificationMessageType,
    type TripPatternDto,
    type BookingDto,
    type StopSignalOrderDto
} from '@/types/webapi';
import { isDate, isSameDay } from 'date-fns';
import { useBookingMessages } from './useBookingMessages';
import { hasValidStopSignalOrder } from '../functions';

/**
 * Update the trip pattern groups with booking notifications
 * @param tripPatternGroups - The trip pattern groups to update
 * @param bookings - The bookings to check against
 * @returns The updated trip pattern groups
 */
export function updateTripPatternBookingNotifications(
    tripPatternGroups: Record<string, TripPatternDto[]>,
    bookings: BookingDto[],
    stopSignalOrders: StopSignalOrderDto[]
): Record<string, TripPatternDto[]> {
    const processedGroups = { ...tripPatternGroups };

    for (const key in processedGroups) {
        processedGroups[key] = processedGroups[key].map(tripPattern => ({
            ...tripPattern,
            legs: updateNotificationMessage(tripPattern.legs || [], bookings, stopSignalOrders)
        }));
    }

    return processedGroups;
}

/**
 * Update the departure groups with booking notifications
 * @param departureGroups - The departure groups to update
 * @param bookings - The bookings to check against
 * @returns The updated departure groups
 */
export function updateDepartureBookingNotifications(
    departureGroups: Record<string, DepartureDto[]>,
    bookings: BookingDto[],
    stopSignalOrders: StopSignalOrderDto[]
): Record<string, DepartureDto[]> {
    const processedGroups = { ...departureGroups };

    for (const key in processedGroups) {
        processedGroups[key] = updateNotificationMessage(processedGroups[key], bookings, stopSignalOrders);
    }

    return processedGroups;
}

/**
 * Update the notification message for the given items
 * @param items - The items to update
 * @param bookings - The bookings to check against
 * @returns The updated items
 */
export function updateNotificationMessage<T extends LegDto | DepartureDto>(
    items: T[],
    bookings: BookingDto[],
    stopSignalOrders: StopSignalOrderDto[]
): T[] {
    const { generateBookingExistsDescriptionText } = useBookingMessages();

    return items.map(item => {
        const { activeDate, serviceJourneyRef, stopSequence } = item.bookingMetadata || {};
        if (!activeDate || !serviceJourneyRef || !stopSequence) return item;

        const hasExistingBooking = bookings.some(
            booking =>
                isDate(activeDate) &&
                isSameDay(booking.activeDate, activeDate) &&
                booking.serviceJourneyRef === serviceJourneyRef &&
                booking.stopSequence === stopSequence
        );

        const hasExistingStopSignalOrder = stopSignalOrders.some(x =>
            hasValidStopSignalOrder(x, { activeDate, serviceJourneyRef, stopSequence })
        );

        if (!hasExistingBooking && !hasExistingStopSignalOrder) return item;

        // find the index of the notification message that is the online booking arrangement
        const notificationMessageIndex = item.notificationMessages
            ? item.notificationMessages.findIndex(x => x.type === NotificationMessageType.OnlineBookingArrangement)
            : -1;

        const leg = item as LegDto;
        const departure = item as DepartureDto;
        const fromQuay = leg.from?.quay?.name || departure.quay?.name;
        const transportMode = leg.transitInfo?.transportMode || departure.transportMode;

        // if the notification message index is found, update the text and type
        // else add a new notification message
        return {
            ...item,
            notificationMessages:
                notificationMessageIndex !== -1
                    ? item.notificationMessages?.map((msg, index) =>
                          index === notificationMessageIndex
                              ? {
                                    ...msg,
                                    text: generateBookingExistsDescriptionText({ fromQuay, transportMode }),
                                    type: NotificationMessageType.BookingConfirmation
                                }
                              : msg
                      )
                    : [
                          ...(item.notificationMessages || []),
                          {
                              id: 'booking-confirmation',
                              text: generateBookingExistsDescriptionText({ fromQuay, transportMode }),
                              type: NotificationMessageType.BookingConfirmation
                          }
                      ]
        };
    });
}
